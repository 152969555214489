<template>
	<div class="sld_output">
		<MemberTitle :memberTitle="L['我的余额']" memberPath="/member/balance" memberTitleS="余额提现"></MemberTitle>
		<div class="main" v-if="!loading && showLoading">
			<div v-show="showTip" class="tips flex_row_between_center">
				<span>温馨提示：提现手续费为{{ info.extra }}%，最低提现金额为￥{{ info.minMoney ? Number(info.minMoney).toFixed(2) :
		0
}}。</span>
				<img @click="closeTip" src="@/assets/buy/close.png" />
			</div>
			<div class="box">
				<!-- <div class="item">
                <div class="left"><span>提现方式：</span></div>
                <div class="right"><span>{{info.type}}</span></div>
            </div> -->
				<div class="item">
					<div class="left"><span>提现金额：</span></div>
					<div class="right flex_column_start_start">
						<input v-model="info.cashAmount" placeholder="请输入提现金额" maxlength="6" autocomplete="off" />
					</div>
					<div class="money" v-if="info.cashAmount">
						（本次提现手续费<span>￥{{ info.extra ? (Number(info.cashAmount) * Number(info.extra)
		/ 100).toFixed(2) : 0
}}</span>）
					</div>
				</div>
				<template v-if="true">
					<div v-if="info.cashAmount > memberBalance" class="money_tip error">剩余可提现金额不足</div>
					<div v-else class="money_tip">剩余可提现金额：￥{{ memberBalance.toFixed(2) || 0 }}
						&nbsp;&nbsp;(当日充值金额不可提现)
					</div>
				</template>
				<!-- <div class="item">
					<div class="left"><span>支付宝账号：</span></div>
					<div class="right">
						<input v-model="info.accountNumber" placeholder="请输入支付宝账号" maxlength="30"
							autocomplete="off" />
						<div v-if="info.accountNumberErr" class="warning">{{info.accountNumberErr}}</div>
					</div>
				</div>
				<div class="item">
					<div class="left"><span>真实姓名：</span></div>
					<div class="right">
						<input v-model="info.accountName" placeholder="请输入真实姓名" maxlength="20"
							autocomplete="off" />
						<div v-if="info.accountNameErr" class="warning">{{info.accountNameErr}}</div>
					</div>
				</div>
				<div class="item">
					<div class="left"><span>支付密码：</span></div>
					<div class="right">
						<input type="password" v-model="info.payPwd" placeholder="请输入支付密码" maxlength="20"
							autocomplete="new-password" />
						<div v-if="info.payPwdErr" class="warning">{{info.payPwdErr}}</div>
					</div>
				</div> -->

				<div class="item flex_row_start_start">
					<div class="left"><span>收款账户：</span></div>
					<div class="account">
						<Card v-for="(item, index) in bankCards" :key="index" :index="index" :info="item"></Card>
					</div>
				</div>



			</div>
			<div class="btn" @click="submit">发起提现</div>
		</div>

		<div v-else-if="!loading && !showLoading">
			<div style="width:100%;" class="res_state_s flex_column_center_center">
				<img src="@/assets/auth_fail.png" />
				<p>暂无数据</p>
				<div class="button_primary" @click="routerBack">确定</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import MemberTitle from '@/components/MemberTitle';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
	name: "OutputOperate",
	components: {
		MemberTitle,
	},
	setup() {
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const showTip = ref(true);
		const router = useRouter();
		const store = useStore();
		const memberInfo = ref(store.state.memberInfo);
		const isClick = ref(false);
		const bankCards = ref([])
		const chosenBanks = ref(0)
		const info = reactive({
			minMoney: 0,
			extra: 0,
			type: '支付宝',
			cashAmount: '',
			cashAmountErr: '',
			accountNumber: '',
			accountNumberErr: '',
			accountName: '',
			accountNameErr: '',
			payPwd: '',
			payPwdErr: '',
		})
		const memberBalance = ref(0)
		const showLoading = ref(false)
		const closeTip = () => {
			showTip.value = false;
		};
		const loading = ref(true)

		//申请提现
		const submit = () => {
			if (isClick.value) { return; }
			let flag = true;
			if (!info.cashAmount || info.cashAmount == 0) {
				info.cashAmountErr = '请输入提现金额';
				flag = false;
			} else if (info.cashAmount < Number(info.minMoney)) {
				info.cashAmountErr = ('最低提现金额为￥' + Number(info.minMoney).toFixed(2));
				flag = false;
			} else if (info.cashAmount > memberBalance.value) {
				info.cashAmountErr = '剩余可提现金额不足';
				flag = false;
			} else {
				info.cashAmountErr = '';
			}

			let param = {
				accountId: bankCards.value[chosenBanks.value].accountId,
				amount: info.cashAmount,
				source: 2
			}
			proxy
				.$post("v3/member/front/withdrawal/withdrawalApply", param)
				.then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg);
						setTimeout(() => {
							router.back();
						}, 1000)
					} else {
						ElMessage(res.msg);
						isClick.value = false;
					}
				})
		};

		const getSet = () => {
			proxy
				.$get("v3/system/front/setting/getSettings", { names: 'tl_withdraw_member_min,tl_withdraw_member_fee' })
				.then(res => {
					if (res.state == 200) {
						if (res.data[0]) {
							info.minMoney = res.data[0];
						}
						if (res.data[1]) {
							info.extra = res.data[1];
						}
					}
				})
		};


		const getBankCard = () => {
			proxy.$get('v3/member/front/bankAccount/list').then(res => {
				if (res.state == 200) {
					bankCards.value = res.data.list
					let index = bankCards.value.findIndex(i => i.isDefault == 1)
					chosenBanks.value = index > -1 ? index : 0
				}
			})
		}

		const selectCard = index => {
			if (index != chosenBanks.value) {
				chosenBanks.value = index
			}
		}


		const getInfo = () => {
			proxy.$get('v3/member/front/withdrawal/accountInfo').then(res => {
				if (res.state == 200) {
					memberBalance.value = res.data.balanceWithdraw
				}
			})
		}

		const checkOuputEnable = () => {
			proxy.$get('v3/system/front/setting/getSettings', { names: 'withdraw_is_enable,withdraw_alipay_is_enable' }).then(res => {
				if (res.state == 200) {
					let outputEnable = (res.data[0] == '1' && res.data[1] == '1') ? true : false;

					if (outputEnable) {
						let startTime = new Date().getHours()
						if (startTime < 7 || startTime >= 20) {
							proxy.$alert('每日提现时间为7至20时', '提示', {
								confirmButtonText: '确定',
								type: 'warning'
							}).then(() => {
								router.back(1)
							})
							return
						}


						Promise.all(requestList()).then(res => {
							loading.value = false
							if (!res[0].length) {
								proxy.$alert('尚未绑定银行卡，请绑定本人银行卡后 再进行提现操作?', '提示', {
									confirmButtonText: '确定',
									type: 'warning'
								}).then(() => {
									router.back(1)
								})
								return
							}

							if (res[1].state == 1) {
								proxy.$alert('尚未完成签约，完成后可进行 提现操作。', '提示', {
									confirmButtonText: '确定',
									type: 'warning'
								}).then(() => {
									router.back(1)
								})
								return
							}


							if (res[2] != null && (res[2] <= 0)) {
								proxy.$alert('本月可提现次数已用完', '提示', {
									confirmButtonText: '确定',
									type: 'warning'
								}).then(() => {
									router.back(1)
								})
								return
							}

							showLoading.value = true
							getInfo()
							getBankCard()
							getSet();

						})

					} else {
						proxy.$alert('提现功能已关闭', '提示', {
							confirmButtonText: '确定',
							type: 'warning'
						}).then(() => {
							router.back(1)
						})
					}
				}
			})
		}

		const requestList = () => {
			const p1 = new Promise(resolve => {
				proxy.$get('v3/member/front/bankAccount/list').then(res => {
					if (res.state == 200) {
						resolve(res.data.list)
					}

				})
			})

			const p2 = new Promise(resolve => {
				proxy.$get('v3/member/front/openAccount/detail').then(res => {
					if (res.state == 200) {
						resolve(res.data.state)

					}
				})
			})


			const p3 = new Promise(resolve => {
				proxy.$get('v3/member/front/withdrawal/accountInfo').then(res => {
					if (res.state == 200) {
						resolve(res.data.remainWithdrawTime)

					}
				})
			})

			return [p1, p2, p3]
		}



		const Card = (props, context) => {
			let { info } = props
			return (
				<div className={props.index == chosenBanks.value ? 'card chosen' : 'card'} onClick={() => { selectCard(props.index) }}>
					<div className="bank_name">{info.depositBank}</div>
					<div className="bank_no">{info.bankAccount}</div>
					{info.isDefault == 1 && <div className="isDefault">默认</div>}
					{props.index == chosenBanks.value && <img src={require('@/assets/flagTri.png')} alt="" />}
				</div>
			)
		}

		const routerBack = () => {
			router.back(1)

		}

		onMounted(() => {
			checkOuputEnable()
		})

		return { L, memberInfo, isClick, showTip, info, closeTip, submit, getSet, Card, bankCards, memberBalance, showLoading, routerBack, loading }
	}
}
</script>

<style lang="scss" scoped>
.sld_output {
	width: 1007px;
	margin-left: 10px;
	float: left;

	.main {
		width: 100%;
		padding-top: 20px;
		overflow: hidden;
		background-color: white;

		.tips {
			margin-left: 20px;
			margin-right: 20px;
			padding: 14px;
			height: 40px;
			color: #000000;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			background: rgba(233, 32, 36, .1);
			border-radius: 3px;

			img {
				width: 13px;
				height: 13px;
				cursor: pointer;
			}
		}

		.box {
			margin-top: 20px;

			.money_tip {
				color: #999999;
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				margin-left: 233px;
				margin-top: 12px;

				&.error {
					color: $colorMain;
				}
			}

			.item {
				margin-top: 20px;
				margin-left: 105px;
				display: flex;

				.left {
					width: 110px;
					height: 40px;
					line-height: 40px;
					margin-right: 18px;
					display: flex;
					align-items: center;
					justify-content: flex-end;

					span {
						position: relative;
						text-align: right;

						&:before {
							display: block;
							content: '*';
							position: absolute;
							left: -8px;
							top: 50%;
							height: 40px;
							margin-top: -18px;
							color: red;
						}
					}
				}

				.right {
					width: 360px;

					span {
						height: 40px;
						line-height: 40px;
					}

					input {
						width: 360px;
						height: 40px;
						border: 1px solid #DDDDDD;
						padding: 10px;
						border-radius: 2px;
					}

					.warning {
						position: relative;
						left: 12px;
						top: 8px;
						line-height: 24px;
						color: #666666;
						font-size: 13px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: $colorMain;
					}
				}

				.money {
					line-height: 40px;
					color: #999999;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					margin-left: 15px;

					span {
						color: $colorMain;
						margin-left: 4px;
					}
				}
			}
		}

		.btn {
			width: 170px;
			height: 40px;
			line-height: 40px;
			color: #fff;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			background: #f30213;
			border-radius: 3px;
			margin: 80px auto;
			cursor: pointer;
		}
	}
}
</style>

<style lang="scss">
.account {
	width: 580px;
	display: flex;
	flex-wrap: wrap;
}

.res_state_s {
	padding-top: 200px;

	img {
		width: 163px;
		height: 114px;
	}

	p {
		font-size: 16px;
		color: #999;
		margin-top: 20px;
	}

	.button_primary {
		width: 150px;
		height: 40px;
		background: #E73439;
		border-radius: 4px;
		text-align: center;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 40px;
		cursor: pointer;
		margin-top: 30px;
	}
}

.card {
	padding: 15px;
	border: 1px solid #DDDDDD;
	width: 280px;
	height: 106px;
	border-radius: 2px;
	position: relative;
	margin-right: 20px;
	margin-bottom: 20px;
	cursor: pointer;

	img {
		width: 34px;
		height: 33px;
		position: absolute;
		bottom: 0;
		right: 0
	}

	&.chosen {
		background: #FFF7F7;
		border: 1px solid #E2231A;
	}

	&:nth-child(2n) {
		margin-right: 0px;
	}

	.bank_name {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		margin-top: 5px
	}

	.bank_no {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin-top: 20px;
	}

	.isDefault {
		width: 40px;
		height: 22px;
		border: 1px solid #E2231A;
		border-radius: 4px;
		font-size: 13px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #E2231A;
		text-align: center;
		line-height: 22px;
		position: absolute;
		top: 10px;
		right: 10px;
	}
}
</style>